import {breakpoint} from 'common/theme';
import {ErrorProps} from 'common/types';
import Link from 'next/link';
import {FC} from 'react';
import styled from 'styled-components';
import Button from 'components/button';
import {MENU_BAR_HEIGHT} from 'components/menu';

const Wrapper = styled.section`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  min-height: 50vh;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding: ${({theme}) => theme.spacing(1)}px;
  padding-top: ${({theme}) => theme.spacing(1) + MENU_BAR_HEIGHT}px;
  ${breakpoint.size('sm')`
    padding: ${({theme}) => theme.spacing(1)}px ${({theme}) => theme.spacing(2)}px;
    padding-top: ${({theme}) => theme.spacing(1) + MENU_BAR_HEIGHT}px;
  `}
`;

const Text = styled.div`
  text-align: center;
  margin-bottom: ${({theme}) => theme.spacing(1.5)}px;
`;

const Error: FC<ErrorProps> = ({text}) => {
  return (
    <Wrapper>
      <Text>{text}</Text>
      <Link href='/' passHref>
        <Button as='a'>Zur Startseite</Button>
      </Link>
    </Wrapper>
  );
};

export default Error;
